<script setup lang="ts">
import { CmsSectionDefault } from '@shopware-pwa/composables-next';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  content: CmsSectionDefault & {
    extensions?: {
      swagCmsExtensionsScrollNavigation?: { displayName?: string };
    };
  };
}>();

const expanded = () => {
  // Check if the hash value is present in the current route
  const hashExists = router.currentRoute.value.hash;
  // value of content.customFields?.accordionOpened
  let accordionOpenedValue = props.content.customFields?.accordionOpened;

  // If both the hash value and accordionOpenedValue exist, then expanded is set to false
  if (hashExists && accordionOpenedValue) {
    return false;
  }

  const displayName =
    props.content.extensions?.swagCmsExtensionsScrollNavigation?.displayName;
  const hasMatchingHash =
    hashExists && hashExists.replace('#', '') === displayName;

  // Otherwise expanded is set to the value of hashExists or Boolean(accordionOpenedValue).
  return Boolean(hasMatchingHash) || Boolean(accordionOpenedValue);
};
</script>

<template>
  <div
    v-if="content.customFields?.accordion === true"
    :id="content.extensions?.swagCmsExtensionsScrollNavigation?.displayName"
    class="cms-section-default cms-section-default__accordion"
  >
    <Accordion class="cms-section-default" :expanded="expanded()">
      <template #title>
        <Headline
          level="h3"
          :content="
            (content.blocks[0]?.slots[0] as any)?.config?.accordionTitle?.value
          "
        />
      </template>
      <template #body>
        <CmsGenericBlock
          v-for="cmsBlock in content.blocks"
          :key="cmsBlock.id"
          class="overflow-auto"
          :content="cmsBlock"
        />
      </template>
    </Accordion>
  </div>
  <div
    v-else
    :id="content.extensions?.swagCmsExtensionsScrollNavigation?.displayName"
    class="cms-section-default"
  >
    <CmsGenericBlock
      v-for="cmsBlock in content.blocks"
      :key="cmsBlock.id"
      class="overflow-auto"
      :content="cmsBlock"
    />
  </div>
</template>
